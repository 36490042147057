import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import Head from 'react-helmet'

import Layout from '../../../components/Layout'

import Location from '../../../Location/Location.js'

const styles = (theme) => {
  return createStyles({
    title: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '34px',
      },
      fontSize: '24px',
    },
    howToApply: {
      [theme.breakpoints.up('lg')]: {
        marginTop: '120px',
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '120px',
        fontSize: '34px',
      },
      marginTop: '120px',
      fontSize: '24px',
    },
    list: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
      margin: '0 0 5px 0',
    },
    text: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
    },
    header: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      fontSize: '12px',
      fontWeight: '800',
      margin: '20px 0',
    },
    description: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      [theme.breakpoints.up('md')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      textTransform: 'uppercase',
      textDecoration: 'underline',
      fontSize: '10px',
      fontWeight: '800',
    },
    section: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      fontFamily: 'Montserrat',
      maxWidth: '90%',
      margin: '0 auto',
      marginTop: '5.8125rem',
      marginBottom: '7.1875rem',
    },
  })
}

const CustomerSuccess = ({ classes }) => {
  return (
    <Layout>
      <Head>
        <title>Customer Success Rep</title>
      </Head>
      <section className={classes.section}>
        <Typography variant="h1" className={classes.title}>
          Customer Success Manager
        </Typography>
        <Location location='Los Angeles' />
        <p className={classes.description}>Description</p>
        <h3 className={classes.header}>About Us:</h3>
        <p className={classes.text}>
          At Avocado Finance, we are a little bit peculiar. We are passionate about creating a personal finance experience that customers love and find valuable. If you are customer-obsessed, like interacting with people, and like learning new things, this is the place for you.
        </p>
        <h3 className={classes.header}>About You:</h3>
        <p className={classes.text}>
          As a Customer Success Manager, you will contribute to a vibrant culture based on customer obsession, trust, respect, integrity, continuous learning, and fun. You have a strong attention to detail and are an expert in processes, identifying opportunities for improvement and communicating barriers to the team. You are flexible while working in an fast-paced and ambiguous environment and can effectively prioritize multiple tasks. Most importantly, you enjoy learning new things, love to delight our customers, and contribute to a strong team culture.
        </p>
        <h3 className={classes.header}>What You Do:</h3>
        <div>
          <p className={classes.list}>-  Assist users with inquiries via email and/or chat regarding using the mobile application, questions about the product, and other
            questions</p>
          <p className={classes.list}>-  Keeps records of customer interactions or transactions, records details of inquiries, complaints, or comments, as well as actions
            taken, using Zendesk
          </p>
          <p className={classes.list}>-  Troubleshoot customer issues, modeling applicable issue and escalating to team as needed</p>
          <p className={classes.list}>-  Work with appropriate departments for further investigation of unresolved issues</p>
          <p className={classes.list}>-  Assess occasions when tickets require escalation and doing so in a timely manner</p>
          <p className={classes.list}>-  Support associate onboarding and provide ongoing process training as needed</p>
          <p className={classes.list}>-  Ensure member satisfaction through high quality interactions and offer ways to appease members as applicable.</p>

          <p className={classes.text}>Candidates must be at least 18 years of age.</p>

          <p className={classes.text}>Avocado Finance does not sponsor for immigration, including for H-1B, TN, and other non-immigrant visas, for this role.</p>
        </div>
        <br />
        <h3 className={classes.description}>BASIC QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  5+ years of experience in a customer-facing environment</p>
          <p className={classes.list}>-  Experience with email and chat support required</p>
          <p className={classes.list}>-  Familiarity with Zendesk or Salesforce ticketing systems</p>
          <p className={classes.list}>-  Available to work shifts during Customer Success office hours M - F, 6am - 7pm and occasional shifts on weekends</p>
          <p className={classes.list}>-  Excellent written and verbal communication skills and ability to adapt tone to company brand</p>
        </div>
        <br />

        <h3 className={classes.description}>PREFERRED QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  Proven work experience as a Customer Success Manager or Account Manager, ensuring customer satisfaction</p>
          <p className={classes.list}>-  Comfortable working in a dynamic environment that requires adaptability</p>
          <p className={classes.list}>-  Demonstrated positive work attitude</p>
          <p className={classes.list}>-  Strong communication and people skills</p>
          <p className={classes.list}>-  Prior experience at a Fintech startup</p>
        </div>
        <h3 className={classes.howToApply}>How to Apply</h3>
        <div>
          <p className={classes.text}>Send an email with your resume to <a href="mailto: support@avocadofinance.io" style={{ color: '#FF0080', fontWeight: '800', textDecoration: 'none' }}>support@avocadofinance.io</a>.</p>
        </div>
      </section>
    </Layout>
  )
}

export default withStyles(styles)(CustomerSuccess)